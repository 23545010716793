//PARTIES COMMUNES ----------------------------------------------------------------------
body,html, ul, li, a{
    margin: 0;
    padding: 0;
    font-family: 'Poppins-Regular';
    list-style: none;
    text-decoration: none;
    color: #000;
}
body, html{
    min-height: 100vh;
}
section {
    padding: 2em 0;
}
h2 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 16px;
}
.a-link{
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-family: 'Poppins-Bold';
    border: 2px solid;
    width: fit-content;
    border-radius: 2em;
    padding: 0.5em 1em;
    margin: 1.5em auto;
    cursor: pointer;
}
nav {
    position: relative;
    .button-burger {
        position: absolute;
        top: 10px;
        right: 5px;
        width: 40px;
        height: 22px;
        background: none;
        box-shadow: none;
        border: none;
        outline: 0;
        cursor: pointer;
        z-index: 10;
        span {
            background-color: #fff;
            position: absolute;
            left: 50%;
            width: 26px;
            height: 2px;
            &:nth-child(1) {
                top: 0;
                transform: translateX(-50%) translateY(0);
                transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s, transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87);
            }
            &:nth-child(2) {
                top: 8px;
                opacity: 1;
                transform: translateX(-50%);
            }
            &:last-child {
                top: 16px;
                transform: translateX(-50%) translateY(0);
                transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s, transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87);
            }
            &.open-burger{
                border: 2px solid #fff;
                border-radius: 50%;
                padding: 10px;
                width: 20px;
                height: 11px;
                right: 10px;
                span {
                    background-color: #fff;
                    width: 10px;
                    &:nth-child(1) {
                        top: 45%;
                        transform: translateX(-50%) translateY(0) rotate(45deg);
                        transition: top 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87), transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s;
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:last-child {
                        top: 45%;
                        transform: translateX(-50%) translateY(0) rotate(-45deg);
                        transition: bottom 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87), transform 0.3s cubic-bezier(0.21, 0.83, 0.26, 0.87) 0.3s;
                    }
                }
            }
        }
    }
    .menu {
        display: none;
    }
    &.open-burger{
        background-color: #fff;
        min-height: 100vh;
        position: fixed;
        z-index: 10;
        width: 100%;
        // transition: all 1s ease-out;
        .menu{
            display: block;
            .menu-title{
                background-color: #000;
                color: #fff;
                padding: 1.5em;
                text-transform: uppercase;
                h2{
                    margin: 0;
                    width: 9em;                
                }
            }
            .menu-list{
                > li{
                    padding: 0 1.5em;
                    border-bottom: 2px solid #a0a0a042;
                    > a{
                        display: flex;
                        align-items: center;
                        padding: 1em 0;
                        img{
                            width: 1.5em;
                            margin-right: 1em;
                        }
                        .icon-grey{
                            display: block;
                        }
                        .icon-green{
                            display: none;
                        }
                    }
                  
                    .submenu, .show-submenu{
                        padding-bottom: 1em;
                        li{
                            a{
                                font-size: 0.9em;
                                span{
                                    font-size: 0.75em;
                                }
                            }
                        }
                    }
                    .submenu {
                        display: none;
                    }
                    &:hover{
                        background-color: #000;
                        > a{
                            color: $green;
                            .icon-grey{
                                display: none;
                            }
                            .icon-green{
                                display: block;
                            }
                        }
                        .submenu{
                            display: block;
                            li {
                                a{
                                    color: #fff;
                                    span{
                                        color: $green;
                                    }
                                }
                            }
                        }
                    }
                    .show-submenu {
                        display: block;
                        background: black;
                        padding: 1em;
                        border: 1px solid #27fb04;
                        min-width: 10em;
                        text-align: center;
                        a {
                            color: #fff;
                        }
                    }
                }
                &:hover{
                    ~.menu-social, ~.menu-lang{
                        display: none;
                    }
                }
            }
            .menu-social{
                display: flex;
                justify-content: center;
                margin: 1em 0;
                img{
                    width: 3em;
                }
                .icon-grey{
                    display: block;
                }
                .icon-green{
                    display: none;
                }
                li{
                    &:hover{
                        .icon-grey{
                            display: none;
                        }
                        .icon-green{
                            display: block;
                        }
                    }
                }
            }
            .menu-lang{
                 display: flex;
                margin-top: 3em;
                position: absolute;
                bottom: 0;
                margin: 1em 0;
                left: calc(50% - 2.5em);
                img{
                    width: 2em;
                    margin: 0 0.25em;
                }
            }
        }
    }
}
.top-page {
    border-bottom: 2px solid;
    padding: 0.25rem 1rem;
    font-size: 0.75em;
    text-transform: uppercase;
    color: #fff;
    > div {
        display: flex;
        flex-direction: column;
        .open-status {
            color: $green;
        }
        .close-status{
            color: $red;
        }
        .separateur {
            display: none;
            margin: 0 1em;
        }
    }
    .top-page-middle-part {
        display: none;
        // display: flex;
        align-items: center;
        img {
            width: 2em;
            margin-right: 1em;
        }
    }
    .menu-lang {
        display: none;
    }
}
//footer
footer {
    background-color: #272222;
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 2em 1rem 0 1rem;
    .mobile-version{
        text-align: center;
    }
    .container{
        display: none;
    }
    .contact-network{
        display: flex;
        justify-content: space-between;
        margin-bottom: 2em;
        flex-direction: column;
        .contact{
            display: flex;
            flex-direction: column;
            font-size: 12px;
            .phone, .email{
                display: flex;
                justify-content: center;
                img{
                    width: 22px;
                    height: 18px;
                    margin-right: 0.25em;
                    color: white;
                    background-color: #272222;
                }
                span{
                    display: block;
                }
            }
        }
        .network{
            padding: 1em;
            a{
                margin-left: 0.25em;
                padding: 0 1em;
                img{
                    width: 1.5em;
                }
            }
        }
    }
    .aboutus-tripadvisor{
        margin-bottom: 2em;
        .aboutus{
            h3{
                margin: 0;
                font-size: 1em;
            }
            ul{
                li{
                    a{
                        color: #fff;
                        font-size: 0.85em;
                        height: 48px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
    .parc-list{
        .parc{
            margin-bottom: 2em;
            h3{
                margin: 0;
                font-size: 1em;
            }
            span{
                font-size: 0.85em;
                display: block;
            }
        }
    }
    .payments{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 1em 0;
        img{
            height: 3em;
        }
    }
    .mentions{
        display: flex;
        font-size: 0.65em;
        justify-content: space-between;
        margin-top: 2em;
        a{
            color: #fff;
        }
    }
    .copyright{
        font-size: 0.75em;
        text-align: center;
        padding: 1em 0;
        border-top: 2px solid #fff;
        margin-top: 0.5em;
    }
}
.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 10;
    .cookie-consent-content{
        padding: 1em;
        text-align: center;
        background-color: $boisGaulois;
        width: 90%;
        margin: 1em auto;
        border-radius: 1em;
        .cookie-consent-buttons {
            display: flex;
            justify-content: center;
            margin-top: 1em;
            button {
                margin: 0 1em;
                cursor: pointer;
            }
        }
    }
}
//FIN PARTIES COMMUNES ------------------------------------------------------------------

//HOMEPAGE ------------------------------------------------------------------------------
//header
.home-header {
    min-height: 75vh;
    color: #fff;
    padding-bottom: 0.25em;
    background-size: cover;
    background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5) ), url(../images/front/backgrounds/bg-header-homepage.webp) no-repeat;

    .menu-destop {
        display: none;
    }
    .header-content {
        .header-title {
            h1 {
                padding: 0 1rem;
                margin: 0.5em 0;
                text-transform: uppercase;
                font-family: 'Poppins-Bold';
                font-size: 1.5em;
                width: 50%;
            }
            p {
                padding: 0 1rem;
                font-size: 0.85em;
                line-height: 1.75em;
            }
        }
        .header-video {
            video {
                width: 100%;
            }
        }
    }
    .mobile-content {
        margin: 1em;
        .show-submenu {
            display: block;
            background: black;
            padding: 1em;
            border: 1px solid #27fb04;
            min-width: 10em;
            text-align: center;
            a {
                color: #fff;
            }
        }
    }
}
//parck list
.parc-description {
    padding: 2em 0;
    .parc-badge {
        text-align: center;
        a {
            margin-bottom: 2em;
            img {
                width: 240px;
            }
            .badge-title {
                display: flex;
                flex-direction: column;
                padding: 1em;
                margin-bottom: 2em;
                .h3 {
                    text-transform: uppercase;
                }
            }
        }
    }
    h2{
        padding: 0 5rem;
        text-align: center;
        font-size: 1em;
    }
    p{
        padding: 0 1em;
        margin: 0;
    }
}
//park caracteristics
.parc-caracteristics {
    display: flex;
    background-size: cover;
    flex-direction: column;
    text-align: center;
    color: white;
    padding: 2em 1em;
    div {
        padding: 1em 0;
        h3{
            font-size: 1em;
        }
        p{
            margin: 0;
            font-size: 0.8em;
            padding: 0 0.25em;
        }
    }
}
//park activities
.parc-activities {
    h2 {
    }
    .activity-list {
        .activity-list-ligne {
            .activity {
                display: flex;
                flex-direction: column;
                position: relative;
                img {
                    max-width: 100%;
                }
                h3 {
                    margin: 0;
                    padding: 4px 1em;
                    font-size: 14px;
                }
                .new {
                    text-transform: uppercase;
                    color: #fff;
                    background-color: #A80000;
                    position: absolute;
                    top: 0.5em;
                    border-radius: 0 1em 1em 0;
                    padding: 0.1em 1em;
                }
            }
        }
    }
}
//parc partnership categories
.parc-partnership-categories{
    .category-list {
        .category{
            display: flex;
            flex-direction: column;
            background-color: #fff;
            width: 75%;
            margin: auto;
            border: 5px solid #fff;
            border-radius: 1em;
            margin-bottom: 2em;
            box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
            img {
                width: 100%;
                margin: auto;
                border-radius: 1em;
            }
            h3 {
                font-size: 14px;
                // padding: 0 1em;
                text-align: center;
            }
        }
    }
}
//parc opinions
.parc-opinions {
    .opinion-list {
        background-size: cover;
        padding: 2em 1em;
        .opinion{
            background-color: #fff;
            box-shadow: 7px 7px #000;
            position: relative;
            .scotch{
                position: absolute;
                top: -18px;
                left: calc(50% - 43px);
            }
            .opinion-header{
                display: flex;
                justify-content: space-between;
                padding: 2em 1rem 0 1rem;
                span{
                    font-family: 'Poppins-Bold';
                }
                div{
                    text-align: right;
                    img{
                        width: 1.5em;
                        vertical-align: baseline;
                    }
                }
            }
            p{
                padding: 0 1rem 1em 1rem;
                overflow: auto;
                max-height: 15em;
            }
        }
        .opinion-buttons{
            display: flex;
            justify-content: center;
            margin-top: 1em;
            button {
                cursor: pointer;
                margin: 1em;
                i{
                    color: #fff;
                    font-size: 0.1em;
                    width: 1rem;
                    height: 1rem;
                    background: #fff;
                    display: block;
                    border-radius: 5em;
                }
                &:nth-child(1) i{
                    background: $yellow;
                    color: $yellow;
                }
            }
        }
    }
    a {
        
    }
}
//parc galerie
.parc-galery {
    .galery {
        text-align: center;
        img {
            max-width: 100%;
            height: 350px;
            object-fit: cover;
        }
    }
}
//parc partner
.parc-partners {
    .partner-list {
        .partner {
            padding: 0 1em;
            display: block;
            text-align: center;
            img {
                max-width: 100%;
            }
        }
    }
    .partner-buttons {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        a{
            cursor: pointer;
            margin: 1em 0.2em;
            i{
                color: #fff;
                font-size: 0.1em;
                width: 1rem;
                height: 1rem;
                background: #fff;
                display: block;
                border-radius: 5em;
            }
            &:nth-child(1) i{
                background: $yellow;
                color: $yellow;
            }
        }
    }
}
//parc discover
.discover {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 0;
    .discover-info {
        padding: 1em;
    }
    img {
        max-width: 100%;
    }
}
//FIN HOMEPAGE --------------------------------------------------------------------------

//page header
.page-header {
    background-size: cover;
    h1{
        text-align: center;
        text-transform: uppercase;
        padding: 2em 0;
        margin: 0;
        color: #fff;
    }
    .menu-destop {
        display: none;
    }
}
//parc intro
.parc-introduction{
    display: flex;
    flex-direction: column;
    padding: 0;
    .destop-version{
        display: none;
    }
    .mobile-version{
        display: block;
    }
    .parc-dessin{
        img{
            width: 100%;
            object-fit: cover;
            max-height: 15em;
        }
    }
    .parc-composition{
        display: inline-block;
        justify-content: center;
        font-family: "Poppins-Bold";
        text-align: center;
        div{
            display: inline-flex;
            flex-direction: column;
            text-align: center;
            padding: 1em;
            width: 40%;
        }
    }
    .parc-descriptif{
        padding: 1rem;
    }
}
//parc parcours list
.parcours-list{
    padding: 2rem 0;
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: 'Poppins-Bold';
    }
    .parcours-carousel{
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        .parcours{
            display: none;
            border-radius: 1em;
            background-color: #fff;
            width: 320px;
            .parcours-header{
                height: 25vh;
                width: 100%;
                img{
                    border-radius: 1em 1em 0 0;
                    border: none;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            h3{
                text-align: center;
            }
            .parcours-caracteristics{
                padding: 0 1rem;
                .parcours-caract{
                    padding-left: 0.25em;
                    display: inline-flex;
                    width: 47%;
                    justify-content: left;
                    text-align: left;
                    img{
                        width: 1em;
                        height: 1em;
                        margin-right: 0.1em;
                    }
                    span{
                        font-size: 0.85em;
                        font-family: "Poppins-Bold";
                    }
                }
            }
            p{
                padding: 0 1rem;
                padding-bottom: 1em;
                font-size: 0.85em;
            }
        }
        .parcours.active{
            display: block;
            margin: 0 1em;
        }
    }
    .parcours-carousel-buttons{
        display: flex;
        justify-content: center;
        a{
            cursor: pointer;
            margin: 1em 0.2em;
            i{
                color: #fff;
                font-size: 0.1em;
                width: 1rem;
                height: 1rem;
                background: #fff;
                display: block;
                border-radius: 5em;
            }
            &:nth-child(1) i{
                background: $yellow;
                color: $yellow;
            }
        }
    }
}
//parc amenagements
.parc-amenagments{
    .amenagment-list{
        .amenagment{
            border-radius: 1em;
            padding: 1rem;
            .icon{
                background: radial-gradient(#fff, $yellow);
                border-radius: 50%;
                width: 3em;
                display: flex;
                height: 3em;
                margin: auto;
                img{
                    margin: auto;
                    width: 1.75em;
                }
            }
            h3{
                text-align: center;
                font-size: 1em;
                font-family: 'Poppins-Bold';
            }
            p{
                text-align: center;
                font-size: 0.85em;
                overflow: hidden;
                height: 6em;
            }
        }
    }
}
//parc activities
.parc-activities-annexes {
    padding: 1em;
    h2 {
        margin: 0;
    }
    > span {
        display: block;
        width: fit-content;
        font-size: 14px;
        margin: auto;
    }
    .activities-list{
        .activity{
            padding: 1em 0;
            display: flex;
            flex-direction: column;
            /* text-align: right; */
            align-items: flex-end;
            img{
                border-radius: 1em;
                width: 100%;
            }
            div {
                display: flex;
                flex-direction: column;
                /* text-align: right; */
                align-items: flex-end;
                h3{
                    text-align: right;
                    text-transform: uppercase;
                    margin: 0;
                }
                p{
                    text-align: right;
                    margin: 0.5em 0;
                    font-size: 0.95em;
                }
                a{
                    display: block;
                    border: 2px solid #000;
                    font-family: 'Poppins-Bold';
                    text-transform: uppercase;
                    border-radius: 2em;
                    padding: 0.5em 0.9em;
                    font-size: 14px;
                    margin: 0.5em 0 0 0;
                    width: fit-content;
                }
            }
            &:nth-child(even){
                div{
                    align-items: flex-start;
                    h3, p{
                        text-align: left;
                    }  
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
//parc-reservation
.reservation{
    background-size: cover;
    color: #fff;
    padding: 2rem 1rem;
    h2{
        text-align: center;
        text-transform: uppercase;
    }
    p{
        font-size: 0.85em;
        text-align: center;
    }
    .payments{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 1em 0;
        img{
            height: 3em;
        }
    }
    iframe{
        min-height: 30em;
        width: 100%;
    }
    h3 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        place-content: space-between;
        align-items: center;
    }
}
//parc-news
.parc-news{
    margin: 2em 1rem;
    padding: 2em 0;
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 40%);
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
    }
    .news-list{
        display: flex;
        flex-direction: column;
        img{
            margin: 1em auto;
            border-radius: 1em;
            object-fit: cover;
            height: 8em;
            width: 60%;
        }
    }
    h3{
        text-align: center;
        font-size: 1em;
        font-family: "Poppins-Bold";
        margin: 1em 0 0 0;
    }
    span{
        text-align: center;
        display: block;
        font-size: 0.85em;
    }
}
//page groupe
.page-group{
    padding: 1rem;
    .group-introduction{
        text-align: center;
    }
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
    }
    .activity-list{
        .activity{
            margin-bottom: 4em;
            img{
                width: 100%;
                border-radius: 1em;
                height: 12em;
                object-fit: cover;
            }
            h3{
                font-size: 0.85em;
                text-align: center;
            }
            p{
                font-size: 0.85em
            }
            .activity-detail-list{
                .activity-detail{
                    display: flex;
                    flex-direction: row;
                    .puce-color{
                        width: 1em;
                        height: 1em;
                        background-color: $orange;
                        border-radius: 50%;
                        margin-right: 1em;
                    }
                    > div {
                        width: 90%;
                        h4{
                            margin: 0;
                            font-size: 0.85em;
                            margin-bottom: 0.5em;
                        }
    
                        p{
                            max-width: 85%;
                            margin: 0;
                            font-size: 0.85em;
                            margin-bottom: 1em;
                        }
                    }
                    p{
                        max-width: 85%;
                        margin: 0;
                        font-size: 0.85em;
                        margin-bottom: 1em;
                    }
                }
                .activity-detail:nth-child(2){
                    .puce-color{
                        background-color: $yellow;
                    }
                }
                .activity-detail:nth-child(3){
                    .puce-color{
                        background-color: $blue;
                    }
                }
            }
        }
    }
    > p{
        font-size: 0.85em;
        text-align: center;
        color: $orange;
    }
}
//page scolaire
.page-scolaire {
    max-width: 100%;
    .group-introduction{
        text-align: center;
        padding: 0 1em;
        font-size: 14px;
    }
    img {
        max-width: 100%;
    }
}
//form contact
.contact-form{
    background-size: cover;
    color: #fff;
    padding: 2em 1rem;
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
    }
    form{
        .form-group{
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
            input{
                height: 2em;
                background: none;
                border: 1px solid #fff;
                outline: none;
                color: #fff;
            }
            textarea{
                background: none;
                border: 1px solid #fff;
                outline: none;
                color: #fff;
            }
        }
        .form-group.checkbox-group{
            flex-direction: row;
            input{
                margin: 0 1em 0 0;
                width: 2em;
            }
            label{
                font-size: 0.85em;
                color: #fff;
                a{
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
        button{
            display: block;
            border: 2px solid #fff;
            color: #fff;
            font-family: "Poppins-Bold";
            text-transform: uppercase;
            border-radius: 2em;
            padding: 0.5em 2.25em;
            font-size: 0.9em;
            margin: auto;
            background: none;
            cursor: pointer;
        }
    }
}
//page schedule
.parc-schedule{
    padding: 1rem;
    h2 {
        margin-bottom: 0;
    }
    span {
        display: block;
        text-align: center;
        margin-bottom: 16px;
        font-size: 14px;
    }
}
//Accès
.access{
    padding: 1rem;
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
    }
    .list-parc{
        .acces-parc{
            color: #fff;
            background-color: $boisGaulois;
            border-radius: 0.5em;
            padding: 0.25em;
            margin: 1em 0;
            h3{
                font-size: 1em;
                margin-bottom: 0;
                padding: 0 0.5rem
            }
            p{
                font-size: 0.85em;
                padding: 0 0.5rem
            }
            .map{
                iframe{
                    width: 100%;
                    height: 20em;
                }
            }
        }
        .acces-parc:last-child{
            background-color: $tyro;
        }
    }
}
//Tarif & pass
.parc-pass{
    padding: 2em 1rem;
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: 'Poppins-Bold';
    }
    .pass-list{
        .pass{
            margin-top: 2.5em;
            .destop-version{
                display: none;
            }
            .mobile-version{
                display: block;
            }
            .pass-header{
                display: flex;
                justify-content: flex-start;
                margin-bottom: 0.5em;
                position: relative;
                img{
                    display: block;
                    height: 100%;
                    width: 40%;
                }
                .pass-title{
                    width: 55%;
                    padding: 0 0.5em;
                    h3{
                        margin: 0;
                        font-size: 1em;
                        font-family: 'Poppins-Bold';
                    }
                }
            }
            .pass-body{
                border-radius: 2em;
                padding: 1em 2em;
                .pass-price{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1em;
                    span{
                        &:nth-child(1){
                            width: 70%;
                        }
                    }
                    &:last-child{
                        margin: 0;
                    }
                }
            }
            a{
                text-align: center;
                display: block;
                text-transform: uppercase;
                font-family: 'Poppins-Bold';
                border: 2px solid;
                width: 7em;
                border-radius: 2em;
                padding: 0.5em 1em;
                margin: 1.5em auto;
            }
            &:nth-child(1){
                h3{
                    color: $tyro;
                }
                .pass-body{
                    background-color: $tyro;
                    color: #fff;
                }
                a{
                    color: $tyro;
                }
            }
            &:nth-child(2){
                h3{
                    color: $boisGaulois;
                }
                .pass-body{
                    background-color: $boisGaulois;
                    color: #fff;
                }
                a{
                    color: $boisGaulois;
                }
            }
            &:nth-child(3){
                h3{
                    color: $canopy;
                }
                .pass-body{
                    background-color: $canopy;
                    color: #fff;
                }
                a{
                    color: $canopy;
                }
            }
        }
    }
}
.tarifs {
    .tarif-list {
        > div {
            > div {
                > span {
                    text-align: center;
                    display: block;
                    font-weight: bold;
                    padding: 0 1em;
                }
                table {
                    thead {
                        tr {
                            th {
                                font-weight: 100;
                                text-transform: uppercase;
                                padding: 0 3px;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .small_lignes {
                    padding: 0 1em;
                    width: fit-content;
                    span {
                        display: block;
                    }
                }
            }
        }
    }
}
//page woodsnack
.page-woodsnack{
    text-align: center;
    > img{
        &:first-child{
            width: 7em;
            margin-top: 1em;
        }
        &:nth-child(3){
            width: 100%;
            height: 15em;
            object-fit: cover;
            object-position: 0 60%;
        }
    }
    h2{
        text-align: center;
        font-size: 1em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
        padding: 0 2em;
    }
    .section-woodsnack-caracteristics{
        h2{
            padding: 0 4em;
        }
        ul{
            li{
                display: flex;
                justify-content: space-evenly;
                img{
                    width: 3em;
                    height: fit-content;
                    align-self: center;
                    margin: 0;
                }
                p{
                    width: 50%;
                    font-size: 0.85em;
                    text-align: left;
                }
            }
        }
        img{
            width: 75%;
            margin: 2em 0;
            border-radius: 1em;
        }
    }
    .section-woodsnack-menu{
        color: white;
        padding: 2em 0;
        h2{
        }
        p{
            font-size: 0.85em;
            padding: 0 1em;
            margin-bottom: 2em;
        }
        ul{
            li{
                display: inline-flex;
                flex-direction: column;
                width: 45%;
                color: #fff;
                img{ 
                    width: 5em;
                    align-self: center;
                }
                h3{
                    font-size: 0.85em;
                    font-weight: 100;
                    margin-bottom: 3em;
                }
                &:nth-child(2){
                    img{
                        width: 6em;
                    }
                }
                &:nth-child(4){
                    img{
                        width: 7em;
                    }
                }
                &:nth-child(5){
                    img{
                        width: 3em;
                    }
                }
                &:nth-child(6){
                    img{
                        width: 4em;
                    }
                }
                &:nth-child(7){
                    img{
                        width: 6em;
                    }
                }
                &:nth-child(8){
                    img{
                        width: 6em;
                    }
                }
            }
        }
    }
    .menu-woodsnack{
        margin: 2em 0;
        h2{
            font-size: 1.25em;
            margin: 0;
        }
        a{
            margin: 2em auto;
            display: block;
            img{ 
                width: 75%;
                border-radius: 1em;
            }
        }
    }
    .woodsnack-map{
        width: 100%;
        iframe{
            height: 15em;
        }
    }
    .acces-woodsnack{
        h2{
            font-size: 1.25em;
        }
        p{
            font-size: 0.85em;
            text-align: left;
            padding: 0 1rem;
        }
        a{
            display: block;
            border: 2px solid #000;
            color: #000;
            font-family: "Poppins-Bold";
            text-transform: uppercase;
            border-radius: 2em;
            padding: 0.5em 2.25em;
            font-size: 0.9em;
            margin: auto;
            background: none;
            width: fit-content;
        }
    }
    .horaire-woodsnack{
        h2{
            font-size: 1.25em;
        }
        > span{
            font-size: 0.85em;
            padding: 0 1rem;
            margin-bottom: 1em;
            display: block;
        }
        .woodsnack-horaires{
            table{
                margin: auto;
                tbody{
                    tr{
                        td{
                            font-size: 0.85em;
                            &:nth-child(1){
                                text-align: right;
                            }
                            &:nth-child(2){
                                text-align: left;
                            }
                        }
                    }
                }
            }
            > div{
                margin: 2em 0;
                font-weight: 500;
                span{
                    font-size: 0.85em;
                    text-align: center;
                }
            }
        }
    }
}
//page Galery
.gallery{
    margin-top: 2em;
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
    }
    .images-list{
        display: flex;
        flex-direction: column;
        .ligne-list{
            display: flex;
            flex-direction: row;
            img{
                width: calc(100%/3);
                object-fit: cover;
                height: 350px;
            }
        }
    }
}
//page partner
.partners{
    padding: 1rem;
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
        margin-bottom: 0;
    }
    p{
        font-size: 0.85em;
        text-align: center;
        margin-top: 0;
        margin-bottom: 2em;
    }
    .partner-list{
        display: inline;
        .partner{
            border: 1px solid #000;
            border-radius: 1em;
            text-align: center;
            display: inline-flex;
            flex-direction: column;
            width: calc(49% - 2px);
            margin-bottom: 1em;
            height: 12em;
            img{
                margin: 0.5em auto;
                height: 4em;
                max-width: 90%;
            }
            span{
                font-family: "Poppins-Bold";
                font-size: 0.85em;
                margin: 0.25em 0;
                padding: 0 1rem;
            }
            p{
                font-size: 0.75em;
                margin: 0;
                padding: 0 0.1em;
            }
        }
    }
}
//page opinion
.opinion-form{
    padding: 2em 1rem;
    .destop-version{
        display: none;
    }
    h2{
        text-align: center;
        font-size: 1.25em;
        font-family: "Poppins-Bold";
        margin: 1em 0;
    }
    form{
        .form-group{
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
            input{
                height: 2em;
                background: none;
                border: 1px solid #000;
                outline: none;
            }
            textarea{
                background: none;
                border: 1px solid #000;
                outline: none;
            }
        }
        .form-group.checkbox-group{
            flex-direction: row;
            input{
                margin: 0 1em 0 0;
                width: 2em;
            }
            label{
                font-size: 0.85em;
                a{
                    text-decoration: underline;
                }
            }
        }
        button{
            display: block;
            border: 2px solid #000;
            font-family: "Poppins-Bold";
            text-transform: uppercase;
            border-radius: 2em;
            padding: 0.5em 2.25em;
            font-size: 0.9em;
            margin: auto;
            background: none;
            cursor: pointer;
        }
    }
}
//page error
.error-404{
    background-size: cover;
    color: #fff;
    padding: 5rem 1rem 0 1rem;
    text-align: center;
    font-family: "Poppins-Bold";
    min-height: calc(100vh - 350px - 256px);
    a{
        display: block;
        border: 2px solid #fff;
        color: #fff;
        font-family: 'Poppins-Bold';
        text-transform: uppercase;
        border-radius: 2em;
        padding: 0.5em 0.9em;
        font-size: 1em;
        margin: 1em auto;
        width: fit-content;
    }
}
//page mentions
.mentions-legales {
    padding: 2em 1em;
    font-size: 14px;
    h2 {
        margin: 2em;
    }
    h3 {
        margin-bottom: 0;
    }
    p {
        margin: 5px 0;
    }
}
.section-login {
    .login-form {
        width: 20em;
        margin: auto;
        .form-group {
            margin-bottom: 1em;
            label {
                display: block;
                text-align: left;
            }
            input[type=email], input[type=password] {
                margin: 0;
                width: 100%;
                border-radius: 0.5em;
                text-decoration: none;
                border-style: none;
                border: 2px solid black;
                height: 1.75em;
                padding-left: 1em;
            }
        }
        .form-group-button {
            display: flex;
            justify-content: right;
            margin-bottom: 0;
            button {
                background-color: white;
            }
        }
    }
}