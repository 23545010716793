// Variables
@import 'variables';
// @import 'modules';
@import 'sections';
// @import 'medias';
@import 'media-queries';

.error-message {
    cursor: pointer;
    div {
        display: flex;
        justify-content: space-between;
        color: white;
        padding: 10px 2em;
        border-radius: 0.5em;
        margin-bottom: 1em;
    }
    .error-message-success {
        background: $boisGaulois;
    }
    .error-message-error {
        background: $darkred;
    }
    svg {
        width: 1.5em;
    }
}

.btn-yellow {
    background-color: #ffbd0b;
    color: black;
}
.btn-guidapp {
    line-height: 19px;
    font-size: 14px;
    font-weight: 600;
    transition: all .2s ease-in-out;
    height: auto;
    padding: 10px 23px;
    cursor: pointer;
    border-radius: 5em;
    border: none;
}