
// $primary-color: #;
// $secondary-color: #27fb04; 

$orange: #ff835a;
$blue: #253b60;
$yellow: #f9b740;
$green: #27fb04;
$red: #ff3f01;
$brown: #4E3B37;
$white: #fff;

$darkblue: darken($blue, 20%);
$lightblue: lighten($blue, 20%);
$darkgreen: #0e5e1a;
$darkred: #c90808;

$admin-primary-color: #0e5e1a;
$admin-secondary-color: #ff835a;
$admin-bg-color: #F3F0E8;

//Parcs
$canopy: #f9b740;
$tyro: #319FB9;
$boisGaulois: #8BCDA1;

//Parcours
$ouistiti: #febce1;
$parcoursVert: #00bb46;
$parcoursJaune: #f8f600;
$parcoursBleu: #009fec;
$parcoursViolet: #b32cab;
$parcoursOrange: #ff7c00;
$parcoursRouge: #ff3f01;
$parcoursNoir: #000000;

// Fonts
@font-face {
    font-family: 'Poppins-Regular';
    src: url('/fonts/Poppins-Regular.ttf');
}
@font-face {
    font-family: 'Poppins-Bold';
    src: url('/fonts/Poppins-Bold.ttf');
}
@font-face {
    font-family: 'ChauPhilomeneOne';
    src: url('/fonts/ChauPhilomeneOne-Regular.ttf');
}

// Typography
$poppins-reg: 'Poppins-Regular';
$poppins-bold: 'Poppins-Bold';
$ChauPhilomeneOne: 'ChauPhilomeneOne';
