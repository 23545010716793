@media (min-width: 480px){
//PARTIES COMMUNES ----------------------------------------------------------------------
    .container{
        padding-left: 5%;
        padding-right: 5%;
    }
//FIN PARTIES COMMUNES ------------------------------------------------------------------
//HOMEPAGE ------------------------------------------------------------------------------
    #app {
        header {
            .header-content {
                .header-title {
                    h1, p {
                        padding: 0;
                    }
                }
            }
        }
    }
    .parc-description{
        margin: 2em 0;
    }
    .parc-activities {
        .activity-list {
            .activity-list-ligne {
                .activity {
                    display: inline-flex;
                    width: 50%;
                    margin-bottom: 16px;
                }
            }
        }
    }
    .parc-partnership-categories {
        .category-list {
            .category {
                width: 40%;
            }
        }
    }
    .parc-opinions {
        .opinion-list {
            > div {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                .opinion {
                    width: 45%;
                }
            }
        }
    }
    .parc-galery {
        .galery {
            img {
                max-width: 50%;
            }
        }
    }
    .parc-partners {
        .partner-list {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 10em;
            .partner{
                img {
                    max-width: 50%;
                }
            }
        }
    }
    .parc-news {
        margin: 2em 5%;
    }
//FIN HOMEPAGE --------------------------------------------------------------------------
}
@media (min-width: 768px){
    //PARTIES COMMUNES ----------------------------------------------------------------------
    h2{
        font-size: 1.5em;
        text-transform: uppercase;
    }
    footer{
        .mobile-version{
            display: none;
        }
        .container{
            display: block;
            > ul {
                display: flex;
                justify-content: space-between;
                h3, span, p, a{
                    color: white;
                }
                li{
                    margin-bottom: 1em;
                    padding-right: 0.5em;
                    h3{
                        margin: 0;
                    }
                    span{
                        font-size: 0.85em;
                    }
                    .tripadvisor{
                        a {
                            width: 49%;
                            display: inline-block;
                            text-align: center;
                            img {
                                width: 4em;
                            margin: 1em 0;
                        }
                        }
                    }
                    .aboutus{
                        ul{
                            li{
                                margin: 0;
                                padding: 0;
                                a{
                                    font-size: 0.75em;
                                }
                            }
                        }
                    }
                    .phone, .email{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        img{
                            width: 1em;
                            margin-right: 0.5em;
                        }
                    }
                }
            }
            .footer-bottom{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 1em 0;
                border-top: 2px solid #fff;
                margin-top: 0.5em;
                .footer-bottom-left{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 85%;
                    .copyright, .mentions{
                        margin: 0;
                        border: none;
                        padding: 0;
                        font-size: 0.75em;
                    }
                    .mentions{
                        width: 55%;
                        a{
                            text-decoration: revert;
                        }
                        
                    }
                }
                img{
                    width: 1em;
                }
            }
        }
    }
    .top-page{
        .menu-lang{
            display: flex;
            li {
                margin: 0 0.2em;
            }
            img{
                width: 2.5em;
            }
        }
    }
    header {
        nav{
            .button-burger{
                display: none;
            }
        } 
        .top-page {
            display: flex;
            justify-content: space-between;
            > div {
                width: 30%;
                display: flex;
                align-self: center;
            }
            .top-page-middle-part {
                width: 30%;
                display: flex;
            }
            .menu-lang {
                justify-content: right;
                align-items: flex-end;
                width: 30%;
                display: flex;
            }
        }
        .header-content {
            padding-bottom: 2em;
        }
        .mobile-content {
            display: none;
        }
    }
    .page-header, .home-header {
        .menu-destop{
            display: block;
            padding: 1em 5%;
            nav{
                .menu{
                    display: block;
                    img, .menu-title, .menu-social, .menu-lang{
                        display: none;
                    }
                    a{
                        color: #fff;
                    }
                    .menu-list{
                        display: flex;
                        justify-content: space-between;
                        > li{
                            > a{
                                text-align: left;
                                text-transform: uppercase;
                                font-family: 'Poppins-Bold';
                            }
                            
                            .submenu, .show-submenu {
                                position: absolute;
                                background: black;
                                padding: 1em;
                                border: 1px solid $green;
                                top: 28px;
                                min-width: 10em;
                                > li{
                                    > a{
                                        font-family: "Poppins-Bold";
                                        span{
                                            font-family: "Poppins-Regular";
                                            color: $green;
                                        }
                                    }
                                    &:hover{
                                        a{
                                            color: $green;
                                        }
                                    }
                                }
                            }
                            .submenu{
                                display: none;
                            }
                            .show-submenu{
                                display: block;
                            }
                            &:hover{
                                > a{
                                    border-bottom: 4px solid $green;
                                }
                                > .submenu {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    //FIN PARTIES COMMUNES ------------------------------------------------------------------
    .contact-form{
        width: 30em;
        margin: 2em auto;
        border-radius: 1em;
    }
    .parc-amenagments{
        .amenagment-list{
            text-align: center;
            .amenagment{
                display: inline-block;
                flex-direction: column;
                justify-content: center;
                border: 1px solid #000;
                border-radius: 1em;
                margin: 1em;
                width: 18em;
                min-height: 12em;
            }
        }
    }
    .parc-activities-annexes{
        .activities-list{
            .activity{
                display: flex;
                justify-content: space-between;
                flex-direction: row-reverse;
                div{
                    width: 50%;
                    padding: 1em;
                    margin: auto;
                }
                img{
                    width: 50%;
                    height: 18em;
                    object-fit: cover;
                }
                &:nth-child(even){
                    flex-direction: row;
                }
            }
        }

    }
    .parc-news{
        .news-list{
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            padding: 1em;
            img{
                width: 30%;
                height: 12em;
                margin: 0;
            }
        }   
    }
    .discover{
        flex-direction: row;
        height: 15em;
        img{
            width: 50%;
            object-fit: cover;
        }
        .discover-info{
            width: 50%;
            padding: 5% 0;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p{
                padding: 0 10%;
            }
            a{
                margin: 0 0 0 10%;
            }
        }
    }
    .opinion-form{
        display: flex;
        flex-direction: row-reverse;
        padding: 0;
        > div{
            width: 50%;
            padding: 2em 5%;
        }
        .destop-version{
            display: block;
            width: 50%;
            object-fit: cover;
        }        
    }
    .parc-opinions {
        .opinion-list {
            > div {
                .opinion {
                    p {
                        font-size: 14px;
                    }
                }
            }
            .opinion-buttons {
                height: fit-content;
            }
        }
    }
    .reservation{
        padding: 2rem 5%;
        h3 {
            flex-direction: row;
        }
    }
    .access{
        padding: 2em 5%;
        .list-parc{
            display: flex;
            justify-content: space-between;
            .acces-parc{
                width: 45%;
            }
        }
    }
    .parc-pass{
        margin: 2em 0;
        .pass-list{
            display: flex;
            justify-content: space-between;
            .pass{
                width: 32%;
                margin: 0;
                .destop-version{
                    display: block;
                }
                .mobile-version{
                    display: none;
                }
                .pass-header{
                    margin: 0;
                    text-align: center;
                    img{
                        margin: auto;
                        width: 60%;
                    }
                }
                .pass-body{
                    padding: 1rem;
                    .pass-title{
                        margin-bottom: 1em;
                        h3, span{
                            color: #fff;
                            text-align: center;
                            margin: 0 auto;
                            display: block;
                        }
                    }
                    .pass-price{
                        font-size: 0.85em;
                        min-height: 1em;
                        span:nth-child(1) {
                            width: 75%;
                        }
                    }
                    a{
                        text-align: center;
                        display: block;
                        text-transform: uppercase;
                        font-family: 'Poppins-Bold';
                        border: 2px solid;
                        width: 7em;
                        border-radius: 2em;
                        padding: 0.5em 1em;
                        margin: 1.5em auto;
                        color: #fff;
                        border-color: #fff;
                    }
                }
            }
        }
    }
    .parc-introduction{
        .destop-version{
            display: flex;
            .parc-dessin{
                width: 50%;
                height: auto;
                overflow: hidden;
                display: flex;
                img{
                    width: 100%;
                    max-height: fit-content;
                    max-height: 30em;
                }
            }
            .parc-descriptif{
                width: 50%;
                padding-right: 5%;
                align-self: center;
            }
        }
        .mobile-version{
            display: none;
        }
        .parc-composition{
            display: flex;
            padding: 2em 0;
            > div {
                margin: 0;
                padding: 0 1em;
                width: fit-content;
                display: flex;
                font-size: 20px;
            }
        }
    }
    .parcours-list {
        .parcours-carousel {
            padding: 0 5%;
        }
    }
    .page-scolaire {
        padding: 0 5%;
    }
    .parc-partners {
        .partner-list {
            .partner {
                width: 100px;
                img {
                    max-width: 100%;
                }
            }
        } 
    }
    .page-woodsnack{
        .section-woodsnack-caracteristics{
            margin: 4em 0;
            > div{
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                margin: 2em 0;
                img, ul{
                    margin: auto;
                    width: 50%;
                }
                img{                    
                    object-fit: cover;
                    height: 20em;
                }
            }
        }
        .section-woodsnack-menu{
            ul{
                li{
                    width: 30%;
                }
            }
        }
        .access-horaire{
            display: flex;
            margin: 2em 0;
            .acces-woodsnack, .horaire-woodsnack{
                width: 50%;
                .woodsnack-horaires{
                    display: flex;
                }
            }
        }
    }
    .parc-caracteristics {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        div{
            img {
                width: 4em;
            }
            h3{
                font-size: 1em;
                min-height: 3em;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            p{
                font-size: 0.85em;
            }
        }
    }
    .parc-partnership-categories{
        .category-list{
            display: flex;
            padding: 0 10%;
            justify-content: space-between;
            .category{
                width: 25%;
                margin: 0;
                h3{
                    width: 100%;
                }
            }
        }
    }
    .page-group {
        .activity-list {
            .activity{
                display: flex;
                img{
                    width: 50%;
                    height: 20em;
                    margin: auto;
                }
                h3{
                    font-size: 1.5em;
                    text-align: left;
                }
                .activity-detail{
                    padding-left: 1em;
                }
                &:nth-child(odd){
                    > div{
                        padding-left: 1em;
                        width: 50%;
                    }
                }
                &:nth-child(even){
                    flex-direction: row-reverse;
                    > div{
                        padding-right: 1em;
                        width: 50%;
                    }
                }
            }
        }
    }
}
@media (min-width: 1024px){
//PARTIES COMMUNES ----------------------------------------------------------------------
    .container{
        padding-left: 10%;
        padding-right: 10%;
    }
    .page-header {
        .menu-destop{
            padding: 1em 10%;
        } 
        h1{
            font-size: 4em;
            padding: 1em 0;
        }
    }
//FIN PARTIES COMMUNES ------------------------------------------------------------------
    .home-header {
        .menu-destop{
            padding: 1em 10%;
        }
        .header-content {
            display: flex;
            padding: 1em 10%;
            margin: 5% 0;
            .header-title{
                width: 30%;
                h1 {
                    padding: 0;
                }
                p {
                    padding: 0;
                }
            }
            .header-video{
                text-align: center;
                margin: auto;
                width: 70%;
            }
        }
    }
    .reservation {
        padding: 2rem 10%;
    }
    .parc-description{
        .parc-badge{
            display: flex;
            justify-content: space-between;
            .h3{
                font-weight: bold;
            }
        }
    }
    .parc-activities {
        .activity-list {
            .activity-list-ligne {
                .activity {
                    padding: 0 2.5%;
                    width: 45%;
                    img {
                        border-radius: 1em 1em 0 0;
                    }
                    h3 {
                        border-radius: 0 0 1em 1em;
                        padding: 16px 2em;
                    }
                    .new {
                        top: 1.5em;
                    }
                }
            }
        }
    }
    .parc-galery {
        .galery {
            display: inline-block;
            text-align: center;
            img {
                max-width: 84%;
                display: inline-block;
                margin: 1em;
            }
        }
    }
    .parc-news {
        margin: 2em 10%;
    }
    .parc-opinions {
        .opinion-list {
            > div {
                .opinion {
                    width: 30%;
                }
            }
        }
    }
}
@media (min-width: 1280px){}